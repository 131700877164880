import api from 'services/api';
import {
  FETCH_QUESTIONS_START,
  FETCH_QUESTIONS_SUCCESS,
  FETCH_QUESTIONS_ERROR,
  POST_ANSWER_START,
  POST_ANSWER_SUCCESS,
  POST_ANSWER_ERROR,
  POST_COMMENT_START,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_ERROR,
  REMOVE_ANSWER_SUCCESS,
  REMOVE_ANSWER_ERROR,
  POST_COMMENT_TO_OPTION_SUCCESS,
  POST_COMMENT_TO_OPTION_ERROR,
} from 'constants/question';

export const getAllQuestionsBySp = id => async dispatch => {
  try {
    dispatch({ type: FETCH_QUESTIONS_START });
    const response = await api.getAllQuestionsBySp(id);
    const sections = response.data.data;

    sections.forEach(section => {
      // Get comment for the first answer
      section.questions.forEach(question => {
        question.comment = question.comment ? question.comment : '';
        // Add question errorField
        question.errorMsg = '';

        // Add question loading status
        question.loading = false;
      });
    });
    dispatch({ type: FETCH_QUESTIONS_SUCCESS, payload: { sections } });
  } catch (error) {
    // Global quesitons error
    dispatch({
      type: FETCH_QUESTIONS_ERROR,
      payload: { error: error.message },
    });
  }
};

export const postAnswer = (sectionId, data) => async dispatch => {
  try {
    dispatch({
      type: POST_ANSWER_START,
      payload: { sectionId, questionId: data.idQuestion },
    });

    const response = await api.postAnswer(data);
    const answers = response.data;

    dispatch({
      type: POST_ANSWER_SUCCESS,
      payload: { sectionId, questionId: data.idQuestion, answers },
    });
  } catch (error) {
    dispatch({
      type: POST_ANSWER_ERROR,
      payload: {
        sectionId,
        questionId: data.idQuestion,
        message: error.response.data.message,
      },
    });
  }
};

export const postCommentToAnswer = (sectionId, data) => async dispatch => {
  try {
    dispatch({
      type: POST_COMMENT_START,
      payload: { sectionId, questionId: data.idQuestion },
    });
    const { comment, idQuestion } = data;
    await api.postCommentToAnswer(data);
    const payload = { sectionId, questionId: idQuestion, comment };
    dispatch({
      type: POST_COMMENT_SUCCESS,
      payload,
    });
    return payload;
  } catch (error) {
    dispatch({
      type: POST_COMMENT_ERROR,
      payload: {
        sectionId,
        questionId: data.idQuestion,
        message: error.response.data.message,
      },
    });
    throw error;
  }
};

export const postCommentToAnswerOption = (
  sectionId,
  questionId,
  data,
) => async dispatch => {
  try {
    dispatch({
      type: POST_COMMENT_START,
      payload: { sectionId, questionId },
    });
    await api.postCommentToAnswerOption(data);
    dispatch({
      type: POST_COMMENT_TO_OPTION_SUCCESS,
      payload: {
        sectionId,
        questionId,
        data: {
          simulatedPatientId: data.patientId,
          answerOptionId: data.answerOptionId,
          text: data.text,
        },
      },
    });
  } catch (error) {
    dispatch({
      type: POST_COMMENT_TO_OPTION_ERROR,
      payload: { sectionId, questionId, message: error.response.data.message },
    });
    throw error;
  }
};

export const deleteAnswer = (sectionId, data) => async dispatch => {
  try {
    const response = await api.deleteAnswer(data);
    dispatch({
      type: REMOVE_ANSWER_SUCCESS,
      payload: { sectionId, questionId: data.idQuestion },
    });
  } catch (error) {
    dispatch({ type: REMOVE_ANSWER_ERROR, error });
    throw error;
  }
};
