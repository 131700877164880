import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Modal, Radio, Icon, Popconfirm, Spin, Tooltip, message } from 'antd';
// Services
import i18n from 'services/i18n';
import { msgLocalError } from 'services/errorHandler';
import stateToggle from 'services/decorators/stateToggle';
// Actions
import {
  checkListChangeQueueSection,
  getCheckList,
  deleteCheckListSection,
  updateCheckListSection,
  createQuestionInSection,
  importQuestionToSection,
} from 'actions/checkList';
import {
  checkPositiveNumber,
  createMaxNumberChecker,
} from 'services/validations';
// Components
import EditDragTable from 'components/EditDragTable';
import CheckListQuestionTable from './CheckListQuestionTable';
import AddCheckListQuestion from './QuestionModal/AddCheckListQuestion';
import ImportQuestion from './QuestionModal/ImportQuestion';
// Services

const graterThenHundred = createMaxNumberChecker(100);

const mapStateToProps = state => ({
  checkList: state.checkList.data,
  loading: state.checkList.loading,
});

const mapDispatchToProps = {
  checkListChangeQueueSection,
  deleteCheckListSection,
  updateCheckListSection,
  getCheckList,
  createQuestionInSection,
  importQuestionToSection,
};

@withRouter
@i18n('checklistColumns')
@stateToggle('modal')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class CheckListTable extends Component {
  static propTypes = {
    checkList: PropTypes.array.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        dataIndex: '',
        width: '20px',
        className: 'col-drag-and-drop',
        render: () => (
          <span className="icon-drag">
            <Icon />
          </span>
        ),
      },
      {
        title: this.props.translate('section'),
        dataIndex: 'name',
        width: '25%',
        editable: true,
      },
      {
        title: this.props.translate('description'),
        dataIndex: 'description',
        width: '25%',
        editable: true,
        required: false,
      },
      {
        title: (
          <Tooltip
            placement="top"
            title={this.props.translate('checklistWeight', 'tooltips')}
          >
            {this.props.translate('weight')}
          </Tooltip>
        ),
        dataIndex: 'weight',
        width: '15%',
        editable: true,
        inputType: 'number',
        rules: [
          {
            validator: checkPositiveNumber,
            message: this.props.translate('onlyPositiveNumber', 'error'),
          },
          {
            validator: graterThenHundred,
            message: 'Can not be grater then 100',
          },
        ],
      },
      {
        dataIndex: '',
        width: '370px',
        className: 'table-button_centered',
        render: (text, record, index, tableInstance, Context) => {
          const { disabled } = this.props;
          const { editingKey } = tableInstance.state;
          const editable = tableInstance.isEditing(record);

          // If buttons disabled
          if (disabled) {
            return (
              <div className="editable-row-operations">
                <span>
                  <a className="action-btn action-btn-is-disabled">
                    <Icon type="plus" />{' '}
                    {this.props.translate('addQuestion', 'role')}
                  </a>
                  <a className="action-btn action-btn-is-disabled">
                    <Icon type="edit" /> {this.props.translate('edit', 'form')}
                  </a>
                  <a className="action-btn action-btn-is-disabled">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is in edit mode
          if (editable) {
            return (
              <div className="editable-row-operations">
                <span>
                  <Context.Consumer>
                    {form => (
                      <a
                        className="action-btn"
                        onClick={e => {
                          e.stopPropagation();
                          tableInstance.save(form, record.key);
                        }}
                        style={{ marginRight: 8 }}
                      >
                        {this.props.translate('save', 'form')}
                      </a>
                    )}
                  </Context.Consumer>
                  <a
                    className="action-btn"
                    onClick={e => {
                      e.stopPropagation();
                      tableInstance.cancel(record.key);
                    }}
                  >
                    {this.props.translate('cancel', 'form')}
                  </a>
                </span>
              </div>
            );
          }

          // If row is not in edit mode
          return (
            <div className="editable-row-operations">
              <span>
                <a
                  className="action-btn"
                  onClick={this.handleOpenModal(record)}
                >
                  <Icon type="plus" />{' '}
                  {this.props.translate('addQuestion', 'role')}
                </a>
                <a
                  className="action-btn"
                  disabled={editingKey !== ''}
                  onClick={e => {
                    e.stopPropagation();
                    tableInstance.edit(record.key);
                  }}
                >
                  <Icon type="edit" /> {this.props.translate('edit', 'form')}
                </a>
                <Popconfirm
                  title={this.props.translate('areYouSureDelete', 'confirm')}
                  onClick={e => e.stopPropagation()}
                  onCancel={e => e.stopPropagation()}
                  onConfirm={this.handleRemoveSection(record)}
                >
                  <a className="action-btn">
                    <Icon type="delete" />{' '}
                    {this.props.translate('delete', 'form')}
                  </a>
                </Popconfirm>
              </span>
            </div>
          );
        },
      },
    ];

    this.state = {
      activeModalSection: 'new',
      isModalOpen: false,
      sectionId: null,
    };
  }

  componentDidMount() {
    this.props.getCheckList(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.props.getCheckList(this.props.match.params.id);
    }
  }

  handleChangeInQueue = data => {
    this.props.checkListChangeQueueSection(data).catch(error => {
      if (
        error.response.status === 400 &&
        error.response.data.errorCode === 1800
      ) {
        message.error(error.response.data.message);
      } else {
        msgLocalError({ error, translate: this.props.translate });
      }
    });
  };

  handleRemoveSection = section => e => {
    e.stopPropagation();
    this.props.deleteCheckListSection(section.id).catch(error => {
      if (
        error.response.status === 400 &&
        error.response.data.errorCode === 1800
      ) {
        message.error(error.response.data.message);
      }
    });
  };

  handleUpdateSection = section => {
    this.props.updateCheckListSection(section).catch(error => {
      if (error.response.status === 400) {
        const errorCode = error.response?.data?.errorCode;
        const errorMessage = error.response?.data?.message;

        if (errorCode === 1800 || errorCode === 1900) {
          message.error(errorMessage);
        }
      }
      throw error;
    });
  };

  handleOpenModal = section => e => {
    e.stopPropagation();
    this.setState({ isModalOpen: true, sectionId: section.id });
  };

  handleCloseModal = e => this.setState({ isModalOpen: false });

  handleChangeActiveModalSection = e =>
    this.setState({ activeModalSection: e.target.value });

  handleCreateQuestion = fields => {
    const { sectionId } = fields;
    // Remove section id from request body
    delete fields.sectionId;
    // Remove ids they are only for rendering purposes
    fields.criteriaRequestList.forEach(criteria => delete criteria.id);
    fields.questionOptionRequestList.forEach(option => delete option.id);

    this.props.createQuestionInSection(sectionId, fields).catch(error => {
      if (
        error.response.status === 400 &&
        error.response.data.errorCode === 1800
      ) {
        message.error(error.response.data.message);
      }
    });
  };

  handleImportQuestion = fields => {
    this.props.importQuestionToSection(fields.idSection, fields.idQuestion);
  };

  render() {
    const dataSource = this.props.checkList.map(item => ({
      ...item,
      key: item.id,
    }));

    return (
      <React.Fragment>
        <Modal
          destroyOnClose
          className="checklistQuestion-modal"
          title={this.props.translate('addQuestion', 'role')}
          visible={this.state.isModalOpen}
          onCancel={this.handleCloseModal}
          bodyStyle={{ padding: '0px' }}
          footer={null}
        >
          <div className="radio-buttons">
            <Radio.Group
              onChange={this.handleChangeActiveModalSection}
              value={this.state.activeModalSection}
            >
              <Radio.Button value="new">
                {this.props.translate('new', 'form')}
              </Radio.Button>
              <Radio.Button value="import">
                {this.props.translate('import', 'form')}
              </Radio.Button>
            </Radio.Group>
          </div>
          <div className="add-section-body-wrapper">
            {this.state.activeModalSection === 'new' ? (
              <AddCheckListQuestion
                sectionId={this.state.sectionId}
                checkList={this.props.checkList}
                onCancel={this.handleCloseModal}
                onSubmit={this.handleCreateQuestion}
              />
            ) : (
              <ImportQuestion
                checkList={this.props.checkList}
                onCancel={this.handleCloseModal}
                onSubmit={this.handleImportQuestion}
              />
            )}
          </div>
        </Modal>
        <Spin spinning={this.props.loading}>
          <EditDragTable
            expandRowByClick
            expandIconAsCell={false}
            columns={this.columns}
            dataSource={dataSource}
            changeQueue={this.handleChangeInQueue}
            onSave={this.handleUpdateSection}
            expandedRowRender={record => (
              <CheckListQuestionTable
                disabled={this.props.disabled}
                sectionId={record.id}
              />
            )}
          />
        </Spin>
      </React.Fragment>
    );
  }
}

export default CheckListTable;
