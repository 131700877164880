import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button } from 'antd';
// Services
import api from 'services/api';
import i18n from 'services/i18n';

import './styles.less';

const mapStateToProps = state => ({
  role: state.role.info,
});

@withRouter
@i18n('output')
@connect(
  mapStateToProps,
  null,
)
export default class LegacyPreview extends PureComponent {
  static propsTypes = {
    role: PropTypes.object.isRequired,
  };

  state = {
    srcImg: '',
  };

  componentDidMount() {
    this.loadImg();
  }

  loadImg = async () => {
    const { id } = this.props.match.params;
    const { data } = await api.printLegacyRoleChecklist(id);
    const blob = new Blob([data], { type: 'image/png' });
    const url = URL.createObjectURL(blob);
    this.setState({ srcImg: url });
  };

  exportQrCode = () => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.href = this.state.srcImg;
    a.download = `checklistQR_${
      this.props.role.roleInformationResponse.acronym
    }.png`;
    a.click();
    window.URL.revokeObjectURL(this.state.srcImg);
  };

  render() {
    return (
      <div>
        <div className="table-panel preview-panel">
          <div className="preview-panel_title">
            {this.props.role.roleInformationResponse.roleDesignation}
          </div>
          <Button
            icon="export"
            className="table-panel_btn-right"
            onClick={this.exportQrCode}
          >
            {this.props.translate('exportQRCode')}
          </Button>
        </div>
        <img src={this.state.srcImg} alt="qrcode" />
      </div>
    );
  }
}
