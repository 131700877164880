import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Spin, Checkbox, Input, Select, Icon } from 'antd';

import i18n from 'services/i18n';
import {
  getAllQuestionsBySp,
  postAnswer,
  postCommentToAnswer,
  postCommentToAnswerOption,
  deleteAnswer,
} from 'actions/question';
import Questions from './Questions';

import './style.less';

const CASCADER_OPTIONS = [
  {
    value: 'all',
  },
  {
    value: 'answered',
  },
  {
    value: 'unanswered',
  },
];

const mapStateToProps = ({ question }) => ({
  loading: question.loading,
  error: question.error,
  questions: question.questions,
  sections: question.sections,
});

const mapDispatchToProps = {
  postAnswer,
  postCommentToAnswer,
  getAllQuestionsBySp,
  deleteAnswer,
  postCommentToAnswerOption,
};

@withRouter
@i18n('patient')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Questionnaire extends PureComponent {
  state = { search: '', filter: 'all', showComments: true };

  componentDidMount() {
    this.props.getAllQuestionsBySp(this.props.match.params.id);
  }

  toggleShowComments = () =>
    this.setState({ showComments: !this.state.showComments });

  onChangeFilter = filter => this.setState({ filter });

  onSearch = e => this.setState({ search: e.target.value });

  handlePostAnswer = (
    sectionId,
    { idQuestion, comment, idAnswerOption, answer },
  ) => {
    const data = {
      idSP: Number(this.props.match.params.id),
      comment,
      idQuestion,
      idAnswerOption,
      answer,
    };

    this.props.postAnswer(sectionId, data);
  };

  handlePostComment = (sectionId, { idQuestion, comment }) => {
    const data = {
      idSP: Number(this.props.match.params.id),
      comment,
      idQuestion,
    };

    this.props.postCommentToAnswer(sectionId, data);
  };

  handlePostOptionComment = ({
    sectionId,
    questionId,
    answerOptionId,
    comment,
  }) => {
    const data = {
      patientId: Number(this.props.match.params.id),
      answerOptionId,
      text: comment,
    };

    this.props.postCommentToAnswerOption(sectionId, questionId, data);
  };

  handleDeleteAnswer = (sectionId, { idQuestion }) => {
    const data = {
      idSP: Number(this.props.match.params.id),
      idQuestion,
    };

    this.props.deleteAnswer(sectionId, data);
  };

  render() {
    if (this.props.error) {
      return (
        <div className="patient-questionnaire-container">
          <h4>Error</h4>
        </div>
      );
    }

    return (
      <Spin spinning={this.props.loading} delay={500}>
        {!this.props.loading && (
          <div className="patient-questionnaire-container">
            <div className="flex-center patient-questionnaire-header">
              <div className="header-filter-md">
                <Select
                  value={this.state.filter}
                  onChange={this.onChangeFilter}
                >
                  {CASCADER_OPTIONS.map(opt => (
                    <Select.Option key={opt.value}>
                      {this.props.translate(opt.value, 'questionnaireAnswers')}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="header-filter-md">
                <Input
                  value={this.state.search}
                  onChange={this.onSearch}
                  prefix={<Icon type="search" />}
                />
              </div>
              <div className="header-filter-md">
                <Checkbox
                  onChange={this.toggleShowComments}
                  checked={this.state.showComments}
                >
                  {this.props.translate('showComments')}
                </Checkbox>
              </div>
            </div>
            <Questions
              search={this.state.search}
              filter={this.state.filter}
              questions={this.props.questions}
              sections={this.props.sections}
              onAnswer={this.handlePostAnswer}
              onComment={this.handlePostComment}
              onOptionComment={this.handlePostOptionComment}
              showComments={this.state.showComments}
              onDeleteAnswer={this.handleDeleteAnswer}
            />
          </div>
        )}
      </Spin>
    );
  }
}
