import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Icon, Popconfirm } from 'antd';
// Services
import i18n from 'services/i18n';
import { msgLocalError } from 'services/errorHandler';
// Actions
import {
  getCheckListQuestionsInSection,
  checkListChangeQueueQuestion,
  updateQuestionInSection,
  deleteQuestionInSection,
} from 'actions/checkList';
// Components
import EditDragTable from 'components/EditDragTable';
import {
  Option,
  WhiteBg,
  Values,
  CriteriaCount,
  BlueTag,
} from './QuestionsViewParts';
import EditCheckListQuestion from './QuestionModal/EditCheckListQuestion';

const mapStateToProps = (state, ownProps) => ({
  questions: state.checkList.questionsBySection[ownProps.sectionId] || [],
  checkList: state.checkList.data,
});

const mapDispatchToProps = {
  getCheckListQuestionsInSection,
  checkListChangeQueueQuestion,
  updateQuestionInSection,
  deleteQuestionInSection,
};

@i18n('subChecklist')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
class CheckListQuestionTable extends Component {
  static propTypes = {
    questions: PropTypes.array.isRequired,
    checkList: PropTypes.array.isRequired,
    sectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      {
        dataIndex: '',
        width: '20px',
        className: 'col-drag-and-drop',
        render: () => (
          <WhiteBg>
            <span className="icon-drag checklist-icon-drag">
              <Icon />
            </span>
          </WhiteBg>
        ),
      },
      {
        title: this.props.translate('questionCriterias'),
        dataIndex: '',
        render: (text, record) => (
          <WhiteBg vSpace>
            <span className="checklist-text">{record.text}</span>
            <span className="checklist-comment">{record.comment}</span>
            <div className="checklist-tags v-space">
              {record.criteriaList.map(BlueTag)}
            </div>
          </WhiteBg>
        ),
      },
      {
        title: this.props.translate('options'),
        dataIndex: '',
        width: '280px',
        render: (text, { questionOptionList: options }) => (
          <WhiteBg>{options.map(Option)}</WhiteBg>
        ),
      },
      {
        title: this.props.translate('values'),
        dataIndex: '',
        width: '80px',
        render: (text, { questionOptionList: options }) => (
          <WhiteBg>{options.map(Values)}</WhiteBg>
        ),
      },
      {
        title: this.props.translate('criteriaCount'),
        dataIndex: '',
        width: '80px',
        render: (text, { questionOptionList: options }) => (
          <WhiteBg>{options.map(CriteriaCount)}</WhiteBg>
        ),
      },
      {
        dataIndex: '',
        width: '140px',
        render: (text, record, index, tableInstance, Context) => {
          const { disabled } = this.props;

          // If buttons disabled
          if (disabled) {
            return (
              <WhiteBg>
                <div className="editable-row-operations">
                  <span>
                    <a className="action-btn action-btn-is-disabled">
                      <Icon type="edit" />{' '}
                      {this.props.translate('edit', 'form')}
                    </a>
                    <a className="action-btn action-btn-is-disabled">
                      <Icon type="delete" />{' '}
                      {this.props.translate('delete', 'form')}
                    </a>
                  </span>
                </div>
              </WhiteBg>
            );
          }

          // If row is not in edit mode
          return (
            <WhiteBg>
              <div className="editable-row-operations">
                <span>
                  <a
                    className="action-btn"
                    onClick={this.handleOpenModal(record)}
                  >
                    <Icon type="edit" /> {this.props.translate('edit', 'form')}
                  </a>
                  <Popconfirm
                    title={this.props.translate('areYouSureDelete', 'confirm')}
                    onClick={e => e.stopPropagation()}
                    onCancel={e => e.stopPropagation()}
                    onConfirm={e => {
                      e.stopPropagation();
                      this.handleDeleteQuesiton(record);
                    }}
                  >
                    <a className="action-btn">
                      <Icon type="delete" />{' '}
                      {this.props.translate('delete', 'form')}
                    </a>
                  </Popconfirm>
                </span>
              </div>
            </WhiteBg>
          );
        },
      },
    ];

    this.state = {
      isModalOpen: false,
      question: null,
    };
  }

  componentDidMount() {
    this.props.getCheckListQuestionsInSection(this.props.sectionId);
  }

  handleOpenModal = question => e => {
    e.stopPropagation();
    this.setState({ isModalOpen: true, question });
  };

  handleCloseModal = e => this.setState({ isModalOpen: false });

  handleChangeInQueue = data => {
    this.props
      .checkListChangeQueueQuestion(this.props.sectionId, data)
      .catch(error =>
        msgLocalError({ error, translate: this.props.translate }),
      );
  };

  handleDeleteQuesiton = question => {
    this.props.deleteQuestionInSection(question.sectionId, question.id);
  };

  handleUpdateQuestion = question => {
    const { id } = this.state.question;
    // Create new objects and omit id propery
    const criteriaRequestList = question.criteriaRequestList.map(criterion => ({
      text: criterion.text,
      comment: criterion.comment,
      numberInQueue: criterion.numberInQueue,
    }));
    const questionOptionRequestList = question.questionOptionRequestList.map(
      option => ({
        comment: option.comment,
        numberInQueue: option.numberInQueue,
        pointCount: option.pointCount,
        textDescription: option.textDescription,
        values: option.values,
      }),
    );
    // Create data to be send
    const data = {
      ...question,
      criteriaRequestList,
      questionOptionRequestList,
    };

    this.props.updateQuestionInSection(id, data);
  };

  render() {
    const dataSource = this.props.questions.map(question => {
      const newQuestion = {
        ...question,
        key: question.id,
        sectionId: this.props.sectionId,
      };

      newQuestion.criteriaList = question.criteriaList.map(item => ({
        ...item,
        key: item.id,
      }));
      newQuestion.questionOptionList = question.questionOptionList.map(
        item => ({ ...item, key: item.id }),
      );

      return newQuestion;
    });

    return (
      <React.Fragment>
        <Modal
          destroyOnClose
          className="checklistQuestion-modal"
          title={this.props.translate('editQuestion', 'role')}
          visible={this.state.isModalOpen}
          onCancel={this.handleCloseModal}
          bodyStyle={{ padding: '0px' }}
          footer={null}
        >
          <div className="add-section-body-wrapper">
            <EditCheckListQuestion
              sectionId={this.props.sectionId}
              checkList={this.props.checkList}
              question={this.state.question}
              onCancel={this.handleCloseModal}
              onSubmit={this.handleUpdateQuestion}
            />
          </div>
        </Modal>
        <div className="checklist-expanded-row">
          <EditDragTable
            columns={this.columns}
            dataSource={dataSource}
            changeQueue={this.handleChangeInQueue}
            onSave={this.handleUpdateQuestion}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CheckListQuestionTable;
