import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { Button, Modal, Radio, Tooltip, message } from 'antd';

import {
  createCheckListSection,
  importAllCheckListSections,
} from 'actions/checkList';
import { Flex } from 'components/Styled';
import i18n from 'services/i18n';
import Preview from './Preview';
import ImportSection from './ImportSection';
import AddSection from './AddSection';
import CheckListTable from './CheckListTable';
import './styles.less';
import LegacyPreview from './LegacyPreview';

const mapStateToProps = state => ({
  role: state.role.info,
});

const mapDispatchToProps = {
  createCheckListSection,
  importAllCheckListSections,
};

@withRouter
@i18n('role')
@connect(
  mapStateToProps,
  mapDispatchToProps,
)
export default class Checklist extends Component {
  static propTypes = {
    role: PropTypes.shape({
      id: PropTypes.number,
      idForMergingVersions: PropTypes.number,
      isUsedInOsce: PropTypes.bool,
      lastVersion: PropTypes.bool,
      majorVersion: PropTypes.number,
      minorVersion: PropTypes.number,
      roleInformationResponse: PropTypes.object,
      skillId: PropTypes.number,
      targetSystemResponses: PropTypes.array,
      versions: PropTypes.array,
    }).isRequired,
  };

  state = {
    visible: false,
    preview: false,
    activeModalSection: 'new',
  };

  toggleVisible = item => () => this.setState({ [item]: !this.state[item] });

  handleChangeActiveModalSection = e =>
    this.setState({ activeModalSection: e.target.value });

  handleCreateCheckListSection = fields => {
    return this.props
      .createCheckListSection(this.props.role.id, fields)
      .catch(error => {
        if (error.response.status === 400) {
          const errorCode = error.response?.data?.errorCode;
          const errorMessage = error.response?.data?.message;

          if (errorCode === 1800 || errorCode === 1900) {
            message.error(errorMessage);
          }
        }
        throw error;
      });
  };

  handleImportCheckListSection = fields => {
    if (fields.idSection === 'all') {
      fields.importAllSection = true;
      delete fields.idSection;
    } else {
      fields.importAllSection = false;
    }

    fields.idCheckListTo = this.props.role.id;
    this.props.importAllCheckListSections(fields).catch(error => {
      if (
        error.response.status === 400 &&
        error.response.data.errorCode === 1800
      ) {
        message.error(error.response.data.message);
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          destroyOnClose
          title={this.props.translate('addNewSection', 'modalForm')}
          visible={this.state.visible}
          onCancel={this.toggleVisible('visible')}
          bodyStyle={{ padding: '0px' }}
          footer={null}
        >
          <div className="add-section-body-wrapper">
            <div className="radio-buttons">
              <Radio.Group
                onChange={this.handleChangeActiveModalSection}
                value={this.state.activeModalSection}
              >
                <Radio.Button value="new">
                  {this.props.translate('new', 'form')}
                </Radio.Button>
                <Radio.Button value="import">
                  {this.props.translate('import', 'form')}
                </Radio.Button>
              </Radio.Group>
            </div>
            {this.state.activeModalSection === 'new' ? (
              <AddSection
                onSubmit={this.handleCreateCheckListSection}
                onCancel={this.toggleVisible('visible')}
              />
            ) : (
              <ImportSection
                onSubmit={this.handleImportCheckListSection}
                onCancel={this.toggleVisible('visible')}
              />
            )}
          </div>
        </Modal>
        <Modal
          width={400}
          destroyOnClose
          title={this.props.translate('checklistQrCode')}
          visible={this.state.preview}
          onCancel={this.toggleVisible('preview')}
          bodyStyle={{ padding: '0px' }}
          footer={null}
        >
          <LegacyPreview txtBtn={this.props.translate('exportLegacyQRCode', 'output')} />
          <Preview txtBtn={this.props.translate('exportQRCode', 'output')} />
        </Modal>
        <Flex mb={3} justifyContent="space-between">
          <Button
            icon="plus"
            type="primary"
            className="btn-primary_add"
            disabled={!this.props.role.lastVersion}
            onClick={this.toggleVisible('visible')}
          >
            {this.props.translate('addSection')}
          </Button>
          <Tooltip
            placement="top"
            title={this.props.translate('iOscePreview', 'tooltips')}
          >
            <Button icon="qrcode" onClick={this.toggleVisible('preview')}>
              {this.props.translate('preview', 'form')}
            </Button>
          </Tooltip>
        </Flex>
        <div className="table-with-action table-expandable-no-first-td">
          <CheckListTable disabled={!this.props.role.lastVersion} />
        </div>
      </React.Fragment>
    );
  }
}
